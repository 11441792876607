import * as React from "react"
import { Link, graphql } from "gatsby"
import parse from 'html-react-parser';

import Hero from "../../components/elements/Hero"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { GRAPHQL_BASE } from "../../spectory-config";

const Post = ({serverData}) => {

    const post = serverData.post;

    let content = post.content;
    content = content.replace(/\[.*?\]/g, '')
    content = parse(content);

    return (
        <Layout
            contentObjects={[]}
        >
            <SEO title={post.title} />
            <Hero
                headline={post.title}
                image={post.featuredImage.node.sourceUrl}
            />
            <div className="container">
                <div className="col12">
                    {
                        content
                    }
                </div>
            </div>

        </Layout>
    )
}

export default Post



export async function getServerData(context) {

    var axios = require('axios');

    var data = JSON.stringify({
        query: `{ 
            post(id: "${context.params.slug}", idType: SLUG) {
                id
                title
                content
                featuredImage {
                    node {
                        sourceUrl
                    }
                }
            }
        
        }`,
        variables: {}
    });

    var config = {
        method: 'post',
        url: GRAPHQL_BASE,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data
    };

    let response = await axios(config);


    return {
        props: {
            post: response.data.data.post,
        }
    }
}