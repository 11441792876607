import React from "react"
import * as styles from "./hero.module.scss"

import parse from 'html-react-parser';

const Hero = props => {


    let add = {};

    let classes = [];

    if(props.sidemenu_id !== null && props.sidemenu_title !== null && props.sidemenu_id !== "" && props.sidemenu_title !== ""){
        add.sidemenu_id = props.sidemenu_id;
        add.sidemenu_title = props.sidemenu_title;
        classes.push('hasSidemenuAnchor')
    }

    return (
        <div
            className={`${styles.container} ${styles.containerNoPadding} ${classes.join(' ')}`}
            style={{
            }}
            {...add}
        >
            <div className={`${styles.text}`}>
                <div className={`${styles.textInner}`}>
                    <h1>
                        {parse(props.headline)}
                    </h1>
                </div>
            </div>
            <div
                className={`${styles.image}`}
                style={{
                    backgroundImage: `url(${props.image})`
                }}
            ></div>
        </div>
    )
}

export default Hero
